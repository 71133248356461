<template>
  <PxHeader :activeSearchDesktop="false" :activeSearchMobile="false" />
  <section v-if="isBusy2" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section class="bg-agrogo-green-2" v-else>
    <div
      class="bg-agrogo-yellow-1 h-20 flex justify-center items-center md:h-24"
    >
      <div
        class="font-nexa font-normal text-base text-agrogo-green-2 md:text-2xl"
      >
        <span class="font-nexa font-black">Destaca tu Finca</span>
      </div>
    </div>
    <!-- CONTENEDOR DESTACA TU FINCA -->
    <div class="md:px-28 lg:px-28 md:pt-8 lg:pt-8 md:space-x-6 lg:space-x-6">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5 py-4 xl:py-0">
        <div v-for="(item, index) in priorities.slice(0, 2)" :key="index">
          <card-primary
            :item="item"
            :idItem="idItem"
            :isBusy="isBusy"
            @selected="saveData"
          />
        </div>
      </div>
    </div>
    <div
      class="md:px-28 lg:px-28 md:pt-4 lg:pt-4 md:pb-4 lg:pb-4 md:space-x-5 lg:space-x-5"
    >
      <div class="grid grid-cols-1 xl:grid-cols-3 gap-x-4 gap-y-5">
        <div v-for="(item, index) in priorities.slice(2, 5)" :key="index">
          <card-secondary
            :item="item"
            :idItem="idItem"
            :isBusy="isBusy"
            @selected="saveData"
          />
        </div>
      </div>
    </div>
    <form action="https://checkout.wompi.co/p/" method="GET" id="form-wompi">
      <!-- OBLIGATORIOS -->
      <input type="hidden" name="public-key" v-model="formData.public_key" />
      <input type="hidden" name="currency" v-model="formData.currency" />
      <input
        type="hidden"
        name="amount-in-cents"
        v-model="formData.amount_in_cents"
      />
      <input type="hidden" name="reference" v-model="formData.reference" />
      <!-- OPCIONALES -->
      <input
        type="hidden"
        name="signature:integrity"
        v-model="formData.signature_integrity"
      />
      <input
        type="hidden"
        name="redirect-url"
        v-model="formData.redirect_url"
      />
      <!-- <button type="submit" class="button" /> -->
    </form>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
// Mixins
import validationToken from "@/mixins/validationToken";
// components
import PxHeader from "@/components/header/PxHeader";
import CardPrimary from "../publishFarms/components/step8/CardPrimary";
import CardSecondary from "../publishFarms/components/step8/CardSecondary";

export default {
  inject: ["provider"],
  mixins: [validationToken],
  components: {
    CardPrimary,
    CardSecondary,
    PxHeader,
  },
  data() {
    const me = this;
    const wompiKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_WOMPI_KEY_TEST
        : process.env.VUE_APP_WOMPI_KEY;
    return {
      repository: me.provider.publishFarmRepository,
      myFarmRepository: me.provider.myFarmRepository,
      transationRepository: me.provider.transactionRepository,
      slug: me.$route.params.slug,
      isBusy: false,
      isBusy2: true,
      farm: null,
      idItem: null,
      host: null,
      priorities: [],
      formData: {
        public_key: wompiKey,
        currency: "COP",
        amount_in_cents: null,
        reference: null,
        signature_integrity: null,
        redirect_url: null,
      },
    };
  },
  methods: {
    async loadFarm() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.myFarmRepository.find(me.slug, dataAditional);
        me.farm = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    async loadPriorities() {
      const me = this;
      try {
        me.isBusy2 = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllPriorities(dataAditional);
        data.forEach((el) => {
          el.id == 6 ? (el.isDisable = true) : (el.isDisable = false);
        });
        me.priorities = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    async saveData(item) {
      const me = this;
      if (item.wompi_price !== 0) {
        me.$swal
          .fire({
            iconHtml:
              '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            customClass: {
              icon: "no-border-swal-alert",
            },
            text: "Para realizar el pago, sera redirigido a la pasarela de pago de Wompi.",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                const idFarm = me.farm.id;
                me.isBusy = true;
                me.formData.amount_in_cents = item.wompi_price;
                me.formData.reference = item.wompi_reference;
                me.formData.signature_integrity = item.wompi_integrity;
                me.formData.redirect_url = `${
                  me.host
                }/verificando-pago?proId=${idFarm}&verifyPayment=${true}`;
                me.idItem = item.id;
                const dataAditional = await generateHash();
                await me.transationRepository.save({
                  ...dataAditional,
                  property_id: idFarm,
                  priority_id: me.idItem,
                  price: item.wompi_price / 100,
                  reference: item.wompi_reference,
                  hash: item.wompi_integrity,
                });
                document.getElementById("form-wompi").submit();
              } catch (error) {
                const code = error.response.status;
                if (code == 401) {
                  me.validationExpiredToken();
                }
              } finally {
                me.isBusy = false;
              }
            }
          });
      }
    },
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadPriorities();
    me.loadFarm();
    me.host = window.location.origin;
  },
};
</script>

<style></style>
